import * as React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
`

const Header = styled.div`
  margin-bottom: 20px;
`

const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`

const Title = styled.div`
  line-height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC, sans-serif;
  font-weight: bold;
  color: #000000;
`

const Append = styled.div`
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
`

const Desc = styled.div`
  margin-top: 6px;
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
`

const Content = styled.div`
  line-height: 24px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
`

const TeamChapter = (props) => {
  const {
    title, append, desc,
    children,
    ...containerProps
  } = props
  return (<>
    <Container {...containerProps}>
      {(title || append || desc) && (
        <Header>
          {(title || append) && (
            <HeaderTop>
              {title && (
                <Title>{title}</Title>
              )}
              {
                append && (
                  <Append>{append}</Append>
                )
              }
            </HeaderTop>
          )}
          {desc && (
            <Desc>{desc}</Desc>
          )}
        </Header>
      )}
      <Content>{children}</Content>
    </Container>
  </>)
}

export default TeamChapter
