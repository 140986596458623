import * as React from "react";
import styled from "@emotion/styled";
import {Progress} from "antd";
import 'antd/es/progress/style/index.css';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 24px;
`

const Skill = styled.div`
  width: calc(50% - 16px);
`

const Name = styled.div`
  line-height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC, sans-serif;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
`

const TeamSkills = (props) => {
  const {data} = props
  return (<>
    <Container>
      {data.map((skill, i) => (
        <Skill key={i}>
          <Name>{skill.name}</Name>
          <Progress percent={skill.progress} strokeColor='#33D96C' status='success' showInfo={false}/>
        </Skill>
      ))}
    </Container>
  </>)
}

export default TeamSkills
